import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ServicesPage = () => (
  <Layout>
    <SEO title="Services" />
    <section className="relative bg-white overflow-hidden">
      <div className="relative pt-6 pb-16 md:pb-20 lg:pb-24 xl:pb-32">
        <div className="mt-8 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-20 xl:mt-24">
          <div className="lg:grid lg:grid-cols-12 lg:gap-8">
            <div className="md:max-w-2xl md:mx-auto lg:col-span-6">
              <h2 className="mt-1 text-4xl tracking-tight leading-10 font-medium text-gray-900 sm:leading-none sm:text-3xl lg:text-4xl xl:text-5xl">
                Chiropractic
              </h2>
              <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                At White Center Chiropractic we offer a variety of adjustment
                techniques specifically tailored to your needs and preferences.
                Dr. Ian Davis is a Palmer graduate and specializes in adjusting
                by hand with the Palmer Diversified Technique. He is also
                certified in a specific instrument adjusting technique. It is a
                gentle method of adjusting the spine with a small, hand-held
                instrument for patients for those that prefer a lighter
                adjustment method. Dr. Davis is also very proficient in
                adjusting the extremities. He has helped many people with hip,
                knee, ankle, shoulder, elbow, and wrist problems; including
                Carpal Tunnel Syndrome.
              </p>
            </div>
            <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
              <div className="relative mx-auto w-full rounded-lg shadow-lg lg:max-w-md">
                <img
                  className="w-full rounded-lg"
                  src="/chiropractic.jpg"
                  alt="Dr. Ian S. Davis making adjustments"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="py-16 bg-gray-50 overflow-hidden lg:py-24">
      <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
        <div class="hidden sm:block sm:absolute sm:inset-y-0 sm:h-full sm:w-full">
          <div class="relative h-full max-w-screen-xl mx-auto">
            <svg
              class="absolute right-full transform translate-y-full translate-x-1/4 lg:translate-x-1/2"
              width="404"
              height="784"
              fill="none"
              viewBox="0 0 404 784"
            >
              <defs>
                <pattern
                  id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    class="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width="404"
                height="784"
                fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
              />
            </svg>
            <svg
              class="absolute left-full transform -translate-y-3/4 -translate-x-1/4 md:-translate-y-1/2 lg:-translate-x-1/2"
              width="404"
              height="784"
              fill="none"
              viewBox="0 0 404 784"
            >
              <defs>
                <pattern
                  id="5d0dd344-b041-4d26-bec4-8d33ea57ec9b"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    class="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width="404"
                height="784"
                fill="url(#5d0dd344-b041-4d26-bec4-8d33ea57ec9b)"
              />
            </svg>
          </div>
        </div>
        <div className="relative max-w-4xl mx-auto">
          <h4 className="text-2xl leading-8 font-medium text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
            Massage Therapy
          </h4>
          <p className="mt-3 text-lg leading-7 text-gray-500">
            Our three massage therapists are trained in a variety of techniques
            from injury specific therapeutic massage to relaxation massage. All
            three of our massage therapists are long term employees who take
            pride in helping our patients to the best of their abilities.
            Massage therapy can help to reduce healing time as well as improving
            healing quality when used in combination with chiropractic.
            Restoring proper alignment of muscle fibers is crucial in restoring
            balance and alignment to the entire musculoskeletal system after an
            auto accident, work injury, or years of poor posture. Massage
            therapy also has many other health benefits including increased
            blood flow through tissues, detoxification of tissues, and stress
            reduction. Dr. Ian Davis and our massage therapists can work with
            you to develop a plan for treatment of acute and chronic problems.
            We accept auto insurance and LNI for massage as well as self pay.
            Unfortunately we no longer take any commercial insurances. It is
            encouraged but not required that you receive chiropractic care in
            order to receive massage. Give us a call today, you won't be
            disappointed with our massage therapists!
          </p>
        </div>
        <div className="relative mt-12 sm:mt-16 lg:mt-24 max-w-4xl mx-auto">
          <h4 className="text-2xl leading-8 font-medium text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
            Hydrotherapy / Hydromassage
          </h4>
          <p className="mt-3 text-lg leading-7 text-gray-500">
            At White Center Chiropractic we have a state of the art Aquamed
            Hydrotherapy Table. Hydrotherapy is a great way to receive the
            benefits of massage therapy in combination with heat, but in shorter
            timed sessions that treat the entire body. Hydrotherapy uses heated
            streams of water to massage the muscles of the entire back, arms,
            and legs; all without getting wet. The pressure and body areas
            massaged can be set to your needs or preferences. Benefits include
            increased blood flow to the tissues, detoxification of tissues,
            stretching of musculature, relief of pain and stiffness, and
            reduction of stress. Hydrotherapy can help to greatly reduce healing
            time in most acute injuries. It is also great for people with
            chronic pain, stiffness, tightness, or body toxicity.
          </p>
        </div>
        <div className="relative mt-12 sm:mt-16 lg:mt-24 max-w-4xl mx-auto">
          <h4 className="text-2xl leading-8 font-medium text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
            Car Accidents
          </h4>
          <p className="mt-3 text-lg leading-7 text-gray-500">
            The human body is remarkable in its resiliency, but when it comes to
            auto accidents we are no match for the forces that are involved.
            Injuries to the neck have been demonstrated in multiple studies with
            impact speeds as low as 5-10 miles per hour! As many auto accident
            victims know, pain or stiffness often takes time to show up or be
            recognized after an accident. Sometimes people fail to even make the
            connection between a minor fender bender and pain that results days,
            weeks, or even months later. Pain and problems come as the function
            of the spine and muscles is changed by minor disturbances from an
            accident. This is why it is important to have your spine checked by
            a doctor of chiropractic after an automobile accident. Your spine
            houses your nervous system, the control center for the entire body.{" "}
            <br />
            If you have been in an accident, you should get checked as soon as
            possible by a chiropractor. Failure to do so could have a long term
            impact on your health. Dr. Ian Davis is an expert in rehabilitation
            of the spine from trauma such as that commonly suffered in an auto
            accident. He can help you to make the best recovery possible in
            order to get out of pain now and prevent problems in the future!
          </p>
        </div>
      </div>
    </section>
  </Layout>
)

export default ServicesPage
